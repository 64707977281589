/* Header Styles */

/* Container for Header */
.header-container {
  font-family: "Inter", sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 3rem;
  background-color: rgb(226, 225, 225);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 1000;
  transition: all 0.3s ease;
  height: 60px;
}

.hamburger-menu {
  display: none;
}

.sidebar {
  display: none; /* Hide sidebar on desktop */
}

.controls {
  display: flex;
  gap: 1rem;
  align-items: center;
}

/* For Shrink Effect on Scroll */
.header-container.shrink {
  height: 40px;
}

/* Logo Image Styles */
.logo img {
  transition: all 0.3s ease;
  width: 50px;
  height: 50px;
}

/* Shrink Logo when Header shrinks */
.header-container.shrink .logo img {
  width: 40px;
  height: 40px;
}

/* Navigation Links */
.nav-links {
  /* margin-right: 2.5rem; */
  margin-left: auto;
  margin-right: auto;
  display: flex;
  gap: 2rem;
}

/* Individual Navigation Link */
.nav-links a {
  text-decoration: none;
  color: #000000;
  font-weight: 500;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  text-transform: uppercase;
  transition: all 0.3s ease;
  position: relative;
}

/* Hover effect for Navigation Links */
.nav-links a:hover {
  font-weight: bold;
  color: #d40303;
  background-color: rgba(118, 56, 56, 0.1);
  border: 1px solid #d40303;
}

/* Pseudo-element for Hover Effect */
.nav-links a::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2px;
  width: 0;
  background-color: #d40303;
  transition: all 0.3s ease;
}

/* Pseudo-element width transition on hover */
.nav-links a:hover::before {
  width: 100%;
}

/* Responsive Styles for Mobile Devices */
@media (max-width: 768px) {
  .header-container {
    padding: 0.5rem 1rem; /* Reduced padding */
    flex-direction: column; /* Change layout to column to accommodate smaller screens */
    height: auto; /* Set height to auto to accommodate potentially more rows of items */
    width: auto;
  }

  .header-container.shrink {
    height: auto; /* Override shrink height for small screens */
  }

  .logo img {
    width: 40px; /* Reduced logo size */
    height: 40px; /* Reduced logo size */
  }
}

/* Styles for Mobile Devices */
@media (max-width: 768px) {
  .nav-links {
    display: none; /* Hide nav-links on mobile */
  }

  .sidebar {
    position: fixed;
    top: 0;
    left: -400px;
    width: 150px;
    height: 100%;
    background-color: #292929;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 2rem;
    transition: all 0.3s ease;
    z-index: 1001;
  }

  .controls {
    display: flex; /* Ensure controls are displayed on mobile */
  }

  .sidebar.open {
    left: 0; /* Show sidebar when open */
  }

  .sidebar a {
    color: #ffffff;
    text-decoration: none;
    padding: 0.5rem 0;
    font-weight: 500;
    transition: all 0.3s ease;
    margin-top: 1rem;
  }

  .sidebar a:hover {
    color: #d40303;
  }

  .sidebar .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 2rem;
    cursor: pointer;
  }

  .hamburger-menu {
    font-size: 2rem;
    cursor: pointer;
    display: block; /* Show hamburger menu on mobile */
    color: black;
  }

  .dark.hamburger-menu {
    color: #ffffff;
  }
}

.header-container {
  flex-direction: row; /* Ensure header container is a row */
}

.left-container {
  display: flex; /* Display as flex */
  align-items: flex-start; /* Align items at the center */
}

.right-container {
  display: flex; /* Display as flex */
  align-items: center; /* Align items at the center */
}

/* Dark Mode Styles */
.header-container.dark {
  background-color: #292929;
}

.nav-links.dark a {
  color: #ffffff;
}

.nav-links.dark a:hover {
  color: #dc5858;
  background-color: rgba(255, 255, 255, 0.1);
}

.dark-toggle {
  cursor: pointer;
}
