/* src/App.css or another global CSS file */
.container {
  display: flex;
  flex-direction: column;
}

.main-content {
  flex: 1 0 auto;
}

.footer {
  flex-shrink: 0;
}
