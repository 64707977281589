/* Main Page Main Content */

/* Hero Section */
.hero-section {
  font-family: "Inter", sans-serif;
  background-color: white; /* Background color */
  min-height: 50vh; /* Height of the hero section */
  display: flex;
  align-items: center;
  justify-content: center;
}

.mypic {
  width: 250px;
  height: 250px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
}

/* Animation for name */
.name {
  opacity: 0;
  animation: slideInName 2s forwards;
  animation-delay: 0.1s;
  font-size: 2.5rem;
  text-align: center;
}

/* Animation for tagline */
.tagline {
  opacity: 0;
  animation: slideInTagline 2s forwards;
  animation-delay: 0.3s;
  text-align: center;
  font-weight: normal;
}

/* Animation definition for name */
@keyframes slideInName {
  to {
    opacity: 1;
  }
}

/* Animation definition for tagline */
@keyframes slideInTagline {
  to {
    opacity: 1; /* End state: fully visible */
  }
}

@media (max-width: 768px) {
  .name,
  .tagline {
    font-size: 5vw; /* Adjust as needed */
  }

  .mypic {
    margin-top: auto;
    width: 200px;
    height: 200px;
  }
}

/* Content below Hero Section */

/* Short Introduction */
.short-introduction {
  font-family: "Inter", sans-serif;
  padding: 1.5rem;
  margin: 0rem 0;
  text-align: center;
  background: rgb(226, 225, 225);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  font-size: 1.3rem;
  width: 95rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 95rem; /* Updated - change width to max-width */
  width: auto;
}

.highlight {
  font-weight: bold;
  color: #d40303; /* Red color for light mode */
}

@media (max-width: 768px) {
  .short-introduction {
    padding: 1rem; /* Adjusted padding for smaller screens */
    font-size: 0.9rem; /* Adjust font size for smaller screens */
    width: auto;
    margin-top: auto;
  }
}

/* Latest Blogs */

.latest-blogs-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: auto;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
  padding: 0 1rem;
}

.latest-blog-card {
  font-family: "Inter", sans-serif;
  flex-basis: calc(50% - 2rem);
  margin: 1rem;
  background: rgb(226, 225, 225);
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
  margin: 1rem 0.5rem;
}

/* Design modification for mobile screens */
@media (max-width: 768px) {
  .latest-blogs-wrapper {
    justify-content: center; /* Center align cards */
    width: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2rem;
    padding: 0 1rem;
  }

  .latest-blog-card {
    flex-basis: calc(90%); /* Cards take 90% of the width */
    height: auto; /* Fixed height to make it square-ish */
    margin: 10px auto; /* Margin to center align and add some spacing */
    padding: 0.5rem; /* Some internal spacing */
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Space out the image from the text */
  }

  .blog-thumbnail {
    width: 100%;
    height: 60%; /* Making sure image doesn't take full card */
    object-fit: cover; /* Cover the space with the image */
  }
}

.latest-blog-card:hover {
  transform: scale(1.05);
}

.blog-thumbnail {
  width: 100%;
  height: auto;
  display: block;
}

.blog-content {
  padding: 1rem;
}

.learn-more-btn {
  margin-top: 0.5rem;
  padding: 0.5rem 1rem;
  border: none;
  background-color: #d40303;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

/* Responsive Design */
@media (max-width: 768px) {
  .featured-blogs {
    flex-direction: column;
    align-items: center;
  }

  .featured-blog-card {
    width: 100%;
    margin-bottom: 1rem;
  }
}

/* Dark Mode Styles */

.dark.main-content {
  background-color: #000000;
  color: #fff;
}

.dark .hero-section {
  background-color: #000000;
  color: #fff;
}

.short-introduction.dark {
  background: #292929; /* Dark background for dark mode */
  color: white; /* Text color for dark mode */
}

.short-introduction.dark .highlight {
  color: #dc5858;
}

/* Dark Mode Styles For Latest Blogs */

.latest-blog-card.dark {
  background: #292929; /* Dark background */
  color: white; /* Text color */
}

.latest-blog-card.dark .learn-more-btn {
  background-color: #dc5858;
  color: white;
}
