.blogs-page-container {
  font-family: "Inter", sans-serif;
  display: flex;
  flex-direction: column;
  min-height: 80vh;
}

.blogs-heading {
  text-align: center;
  margin-top: 20px;
  font-size: 2rem;
  font-weight: bold;
  color: #333;
}

.blogs-content {
  flex: 1;
  padding: 20px;
}

.dark.blogs-page-container {
  background-color: #000000;
  color: #fff;
}

.dark.blogs-heading {
  background-color: #000000;
  color: #fff;
}

.dark.blogs-content {
  background-color: #000000;
  color: #fff;
}
