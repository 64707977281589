.footer-container {
  font-family: "Inter", sans-serif;
  background-color: rgb(
    226,
    225,
    225
  ); /* Dark background color for better contrast */
  color: #000000; /* Light text color for readability against the dark background */
  padding: 1.5rem; /* Increased padding for better spacing */
  display: flex;
  flex-direction: column; /* Align items in a column */
  align-items: center; /* Center the items */
  /* justify-content: center; */
  height: auto;
}

.testimonials {
  text-align: center;
  margin-bottom: 1rem; /* Add some space between testimonials and views count */
}

.testimonials p {
  margin: 0.5rem 0; /* Add vertical margin between each paragraph */
}

.dark.footer-container {
  background-color: #292929;
  color: white;
}

.dark.Footer {
  background-color: #121212; /* Slightly different dark mode background for a visual change */
  color: #d0d0d0; /* Slightly different dark mode text color */
}
