.about-container {
  font-family: "Inter", sans-serif;
  padding: 20px;
  max-width: 900px;
  margin: 0 auto;
  line-height: 1.8;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  min-height: 70vh;
}

.about-container h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
  text-align: center;
  color: #333;
}

@media (max-width: 768px) {
  .about-container h1 {
    font-size: 2em;
  }
}
.dark.container {
  background-color: #000000;
  color: #fff;
}

.dark.about-container {
  background-color: #000000;
  box-shadow: 0 0 10px rgb(255, 255, 255);
}

.dark .about-container h1 {
  background-color: #000000;
  color: #fff;
}

.dark .about-container p {
  background-color: #000000;
  color: #fff;
}
