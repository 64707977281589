.contact-container {
  font-family: "Inter", sans-serif;
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.6;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  min-height: 75vh;
}

.contact-container h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
  text-align: center;
  color: #333;
}

.contact-container p {
  margin-bottom: 15px;
  font-size: 1.1em;
  color: #333;
}

.dark .contact-container {
  background-color: #000000;
  color: #fff;
}

.dark .contact-container h1 {
  color: #fff;
}

.dark .contact-container p {
  color: #fff;
}

.contact-social-icons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}

.contact-social-icon {
  font-size: 1.5em;
  color: #333;
}

.contact-social-icon:hover {
  color: #d40303;
}

.dark .contact-social-icon {
  color: #fff;
}

.dark .contact-social-icon:hover {
  color: #dc5858;
}
