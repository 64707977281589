/* src/blogs/Blog.css */

.blog-container {
  padding: 20px;
  max-width: 900px;
  margin: 0 auto;
  line-height: 1.8;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
}

.blog-container h1 {
  font-family: "Inter", sans-serif;
  font-size: 2.5em;
  margin-bottom: 20px;
  text-align: center;
  color: #333;
}

.dark.container {
  background-color: #000000;
  color: #fff;
}

.dark.blog-container {
  background-color: #000000;
  box-shadow: 0 0 10px rgb(255, 255, 255);
}

.dark .blog-container h1 {
  background-color: #000000;
  color: #fff;
}

.dark .blog-container p {
  background-color: #000000;
  color: #fff;
}

.blog-container p {
  font-family: "Inter", sans-serif;
  font-size: 1.1em;
  margin-bottom: 20px;
  color: #333;
  /* text-align: justify; */
}

@media (max-width: 768px) {
  .blog-container p {
    font-size: 1em;
  }

  .blog-container h1 {
    font-size: 2em;
  }
}

/* .blog-container img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 20px;
}

.blog-container blockquote {
  padding: 10px 20px;
  margin: 20px 0;
  font-style: italic;
  background-color: #f9f9f9;
  border-left: 5px solid #7c7c7c;
}

.blog-container ul,
.blog-container ol {
  margin-bottom: 20px;
}

.blog-container ul li,
.blog-container ol li {
  margin-bottom: 10px;
} */

/* Additional styling for links */
/* .blog-container a {
  color: #007bff;
  text-decoration: none;
}

.blog-container a:hover {
  text-decoration: underline;
} */

/* Add other styles as needed */
